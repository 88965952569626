<template>
  <div class="flex flex-col gap-2">
    <div class="font-semibold">
      Options:
    </div>
    <div class="flex gap-2 justify-between">
      <div class="flex flex-col gap-2">
        <div class="flex justify-end gap-4 items-end">
          <span class="text-sm font-semibold text-gray-600">Select Interval</span>
          <input type="checkbox" id="hs-basic-usage" class="relative w-[3.25rem] h-7 p-px bg-gray-100
            border-transparent text-transparent rounded-full cursor-pointer transition-colors ease-in-out
            duration-200 focus:ring-blue-600 disabled:opacity-50 disabled:pointer-events-none checked:bg-none
            checked:text-[#fdd835] checked:border-blue-600 focus:checked:border-blue-600 dark:bg-neutral-800
            dark:border-neutral-700 dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-600
            before:inline-block before:size-6 before:bg-white checked:before:bg-white before:translate-x-0
            checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0
            before:transition before:ease-in-out before:duration-200 dark:before:bg-neutral-400
            dark:checked:before:bg-blue-200" v-model="allObservationsToggle">
          <label for="hs-basic-usage" class="sr-only">switch</label>
          <button type="button"  @click="props.reloadObservations"
            class="py-3 px-4 inline-flex items-center gap-x-2
            text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
            hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit">
            Reload Observations
          </button>
        </div>
        <div class="flex gap-2" v-if="!allObservationsToggle">
          <label>
            <p>Start Date:</p>
            <input type="date" id="summaryStartDate" name="start-date" v-model="startDate" min="2018-01-01"
              :max="today" />
          </label>
          <label>
            <p>End Date:</p>
              <input type="date" id="summaryEndDate" name="end-date" v-model="endDate" min="2018-01-01"
                :max="today" />
          </label>
        </div>
        <div class="flex flex-col gap-2" v-if="allObservationsToggle">
          <div class="flex gap-4 items-center">
            <p>Report type:</p>
            <ReportTypeDropdown :dataArray="reportTypes" :selectedData="selectedReportType"
              :changeSelectedData="changeReportType" />
          </div>
          <div class="flex gap-4 items-center">
            <p>Interval:</p>
            <ReportTypeDropdown :dataArray="intervals" :selectedData="selectedInterval"
            :changeSelectedData="changeInterval" />
          </div>
        </div>
      </div>
      <div class="flex gap-2 justify-center items-center z-50">
        Model:
        <div class="hs-dropdown relative inline-flex">
          <button id="hs-dropdown-default" type="button" class="hs-dropdown-toggle py-3 px-4 inline-flex
            items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white
            text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none
            dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800">
            {{ MODELMAP[props.pickedModel] }}
            <svg class="hs-dropdown-open:rotate-180 size-4" xmlns="http://www.w3.org/2000/svg" width="24"
              height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"><path d="m6 9 6 6 6-6"/></svg>
          </button>
          <div class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100
            opacity-0 hidden min-w-60 bg-white shadow-md rounded-lg p-2 mt-2 dark:bg-neutral-800
            dark:border dark:border-neutral-700 dark:divide-neutral-700 after:h-4 after:absolute
            after:-bottom-4 after:start-0 after:w-full before:h-4 before:absolute before:-top-4
            before:start-0 before:w-full" aria-labelledby="hs-dropdown-default">
            <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800
              hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400
              dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" href="#"
              @click="() => props.pickNewModel('gpt-3.5-turbo-0125')">
              GPT 3.5 Turbo
            </a>
            <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800
              hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400
              dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" href="#"
              @click="() => props.pickNewModel('gpt-4')">
              GPT 4
            </a>
            <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800
              hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400
              dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" href="#"
              @click="() => props.pickNewModel('gpt-4-32k')">
              GPT 4 32K
            </a>
            <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800
              hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400
              dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" href="#"
              @click="() => props.pickNewModel('gpt-4-turbo')">
              GPT 4 Turbo
            </a>
            <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800
              hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400
              dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" href="#"
              @click="() => props.pickNewModel('gpt-4o')">
              GPT 4o
            </a>
            <a class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800
              hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400
              dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" href="#"
              @click="() => props.pickNewModel('claude-3-5-sonnet')">
              Claude 3.5 Sonnet
            </a>
          </div>
        </div>
      </div>
      <div class="flex gap-2 items-center">
        <button type="button"  :data-hs-overlay="`#hs-indiIntermediateModal`"
          class="py-3 px-4 inline-flex items-center gap-x-2
          text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
          hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit">
          <span>Intermediate Report</span>
        </button>
        <button type="button"  :data-hs-overlay="`#hs-indiPromptModal`"
          class="py-3 px-4 inline-flex items-center gap-x-2
          text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
          hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit">
          <span v-if="props.prompt.name">Change Prompt</span>
          <span v-if="!props.prompt.name">Pick Prompt</span>
        </button>
        <button type="button" @click="checkForExistingReports"
          class="py-3 px-4 inline-flex items-center gap-x-2
          text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
          hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit"
          :disabled="!props.prompt?.name">
          Generate
        </button>
        <button type="button" @click="props.openAllModal"
          class="py-3 px-4 inline-flex items-center gap-x-2
          text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
          hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit"
          :disabled="!props.prompt?.name">
          Generate All
        </button>
      </div>
    </div>
  </div>
  <PromptModal :prompt="props.prompt" modalName="indiPromptModal" :usePrompt="props.usePrompt" @change:default-prompt="changedDefaultPrompt" />
  <IntermediateModal modalName="indiIntermediateModal" :studentId="props.studentId" :model="props.pickedModel" 
    :intermediate-reports="props.intermediateReports" :reload-reports="props.reloadIntermediateReports" :reportType="selectedReportType"
    :interval="selectedInterval" />
</template>

<script setup>
import { getTodayDateJSFormat } from '@/lib/utils';
import { ref, watch } from 'vue';
import PromptModal from './PromptModal.vue';
import IntermediateModal from './IntermediateModal.vue';
import ReportTypeDropdown from './ReportTypeDropdown.vue';

const props = defineProps([
  'pickedModel', 'pickNewModel', 'prompt',
  'usePrompt', 'reloadObservations', 'checkForExistingReports',
  'openAllModal', 'studentId', 'intermediateReports',
  'reloadIntermediateReports'
])

const emit = defineEmits([
  'change:start', 'change:end',
  'change:loadAll', 'change:defaultPrompt'
])

const changedDefaultPrompt = () => {
  emit('change:defaultPrompt')
}


const today = getTodayDateJSFormat();

const allObservationsToggle = ref(true);
const startDate = ref("2024-08-01");
const endDate = ref(today);

function getSchoolTerms(endDate) {
    let terms = [];
    let startYear = 2024;
    let currentYear = startYear;
    let currentMonth = 1;
    let endYear = endDate.getFullYear();
    let endMonth = endDate.getMonth() + 1;

    while (currentYear < endYear || (currentYear === endYear && currentMonth <= endMonth)) {
        let termLabel = currentMonth === 1 ? `January-June ${currentYear}` : `August-December ${currentYear}`;
        terms.push(termLabel);
        
        if (currentMonth === 1) {
            currentMonth = 8;
        } else {
            currentMonth = 1;
            currentYear++;
        }
    }
    return terms;
}

function getAcademicYears(endDate) {
    let years = [];
    let startYear = 2023; // Adjusted to include 2023-2024
    let currentYear = startYear;
    let endYear = endDate.getFullYear();
    let endMonth = endDate.getMonth() + 1;

    while (currentYear < endYear || (currentYear === endYear && endMonth >= 6)) {
        years.push(`${currentYear}-${currentYear + 1}`);
        currentYear++;
    }
    return years;
}

function getIntervalDates(type, interval) {
    if (type === 'Term') {
        let [term, year] = interval.split(' ');
        let start, end;
        if (term === 'January-June') {
            start = new Date(`${year}-01-01`);
            end = new Date(`${year}-06-30`);
        } else if (term === 'August-December') {
            start = new Date(`${year}-08-01`);
            end = new Date(`${year}-12-31`);
        }
        return { start, end };
    } else if (type === 'Yearly') {
        let [startYear, endYear] = interval.split('-').map(Number);
        return {
            start: new Date(`${startYear}-08-01`),
            end: new Date(`${endYear}-06-30`)
        };
    }
    return null;
}


// const reportTypes = ['Monthly', 'Term', 'Yearly']
const reportTypes = ['Term', 'Yearly']
const selectedReportType = ref('Term')

const intervals = ref(getSchoolTerms(new Date()))
const selectedInterval = ref(intervals.value[intervals.value.length - 2])
console.log("intervals", intervals)

const emitChangedInterval = () => {
  const { start, end } = getIntervalDates(selectedReportType.value, selectedInterval.value)
  emit('change:start', start)
  emit('change:end', end)
}

const changeReportType = (newType) => {
  if (selectedReportType.value !== 'Term' && newType === 'Term') {
    const terms = getSchoolTerms(new Date())
    intervals.value = terms;
    selectedInterval.value = terms[terms.length - 1]
    emit('change:interval', terms[terms.length - 1]);
  } else if (selectedReportType.value !== 'Yearly' && newType === 'Yearly') {
    const academicYears = getAcademicYears(new Date())
    intervals.value = academicYears;
    selectedInterval.value = academicYears[academicYears.length - 1]
    emit('change:interval', academicYears[academicYears.length - 1]);
  }
  emit('change:report', newType);
  selectedReportType.value = newType
  emitChangedInterval();
}

const changeInterval = (newInterval) => {
  selectedInterval.value = newInterval;
  console.log("new interval", newInterval)
  emit('change:interval', newInterval);
  emitChangedInterval();
}

watch([startDate, endDate, allObservationsToggle], ([newStart, newEnd, newAll]) => {
  emit('change:start', newStart)
  emit('change:end', newEnd)
  emit('change:loadAll', newAll)
})

const MODELMAP = {
  'gpt-3.5-turbo-0125': 'GPT-3.5 Turbo',
  'gpt-4': 'GPT-4',
  'gpt-4-32k': 'GPT-4 32K',
  'gpt-4-turbo': 'GPT-4 Turbo',
  'gpt-4o': 'GPT-4o',
  'claude-3-5-sonnet': 'Claude 3.5 Sonnet'
}
</script>
<template>
  <slot name="trigger" />

  <div :id="`hs-${props.modalName}`" class="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 
    hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto 
    pointer-events-none flex flex-col justify-center items-center">
    <div class="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all 
       sm:w-full w-[95%] m-3 min-h-2/3 max-h-5/6 lg:w-1/2">
      <div class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto h-full">
        <div class="">
          <div class="flex justify-between items-center px-4 pt-4">
            <h3 class="font-bold text-[#6B7280] text-lg md:text-xl">
              Saved Reports
            </h3>
            <button type="button" class="flex justify-center items-center size-7 text-sm font-semibold
              rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50
              disabled:pointer-events-none" :data-hs-overlay="`#hs-${props.modalName}`">
              <span class="sr-only">Close</span>
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
            </button>
          </div>
        </div>
        <div class="p-4 overflow-auto lg:max-h-[70vh]">
          <div class="bg-[#FFFAE6] p-4 rounded-md space-y-4">
            <div class="flex flex-col gap-1">
              <div :class="cn(`cursor-pointer`, !promptExpanded && 'text-ellipsis line-clamp-4 text-gray-500')"
                @click="promptExpanded = !promptExpanded">
                Prompt: {{ props.prompt?.prompt_name }}
              </div>
              <div :class="cn(`cursor-pointer`, !promptExpanded && 'text-ellipsis line-clamp-4 text-gray-500')"
                @click="promptExpanded = !promptExpanded">
                {{ props.prompt?.prompt }}
              </div>
              <button type="button" @click="hideFullPrompt = true" 
                v-if="promptResource.data?.status === 'success' && !hideFullPrompt"
                class="py-3 px-4 inline-flex items-center gap-x-2
                text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
                hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit">
                Hide Full Prompt
              </button>
              <div v-if="!props.shouldLoadAll" class="flex gap-4">
                <div>{{ props.startDate }}</div>
                <div> to </div>
                <div>{{ props.endDate }}</div>
              </div>
              <div v-else>
                All Observations
              </div>
              <div>{{ MODELMAP[props.model] }}</div>
            </div>
            <div v-if="promptResource.data?.status === 'success' && !hideFullPrompt"
              class="flex flex-col gap-2">
              <div class="flex flex-col gap-2 border rounded-md p-2 whitespace-pre-wrap">
                {{ promptResource.data.log_text }}
              </div>
              <h4 class="font-semibold">Full Prompt:</h4>
              <div>{{ promptResource.data.prompt[0].role }}</div>
              <div class="text-ellipsis text-sm line-clamp-4">{{ promptResource.data.prompt[0].content }}</div>
              <div>{{ promptResource.data.prompt[1].role }}</div>
              <div class="whitespace-pre-line">
                {{ promptResource.data.prompt[1].content }}
              </div>
              <div v-if="promptResource.data.prompt.length >= 3">
                {{ promptResource.data.prompt[2].role }}
              </div>
              <div class="whitespace-pre-line" v-if="promptResource.data.prompt.length >= 3">
                {{ promptResource.data.prompt[2].content }}
              </div>
              <div v-if="promptResource.data.prompt.length >= 4">
                {{ promptResource.data.prompt[3].role }}
              </div>
              <div class="whitespace-pre-line" v-if="promptResource.data.prompt.length >= 4">
                {{ promptResource.data.prompt[3].content }}
              </div>
              <div v-if="promptResource.data.prompt.length >= 5">
                {{ promptResource.data.prompt[4].role }}
              </div>
              <div class="whitespace-pre-line" v-if="promptResource.data.prompt.length >= 5">
                {{ promptResource.data.prompt[4].content }}
              </div>
              <div v-if="promptResource.data.prompt.length >= 6">
                {{ promptResource.data.prompt[5].role }}
              </div>
              <div class="whitespace-pre-line" v-if="promptResource.data.prompt.length >= 6">
                {{ promptResource.data.prompt[5].content }}
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <p>Choose Data:</p>
              <div class="flex gap-4">
                <div class="flex">
                  <label class="text-sm text-gray-500 ms-2 dark:text-neutral-400 flex gap-2">
                    <input type="radio" name="assessment_type" class="shrink-0 mt-0.5 border-gray-200 
                      rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 
                      disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 
                      dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800" 
                      id="singleGenRawRadio" v-model="assessmentType" value="raw">
                    Raw Assessment
                  </label>
                </div>

                <div class="flex">
                  <label class="text-sm text-gray-500 ms-2 dark:text-neutral-400 flex gap-2">
                    <input type="radio" name="assessment_type" class="shrink-0 mt-0.5 border-gray-200 
                      rounded-full text-blue-600 focus:ring-blue-500 disabled:opacity-50 
                      disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 
                      dark:checked:bg-blue-500 dark:checked:border-blue-500 dark:focus:ring-offset-gray-800" 
                      id="singleGenSummaryRadio" v-model="assessmentType" value="summary" >
                    Summary Assessment
                  </label>
                </div>
              </div>
              <div class="flex gap-4 flex-wrap">
                <div class="flex">
                  <label class="text-sm text-gray-500 ms-3 dark:text-neutral-400 flex gap-2">
                    <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
                      focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none 
                      dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 
                      dark:checked:border-blue-500 dark:focus:ring-offset-gray-800" 
                      id="hs-default-checkbox" v-model="selectedData" value="intermediate">
                      Intermediate Report
                  </label>
                </div>

                <div class="flex">
                  <label class="text-sm text-gray-500 ms-3 dark:text-neutral-400 flex gap-2">
                    <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
                      focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none 
                      dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 
                      dark:checked:border-blue-500 dark:focus:ring-offset-gray-800" 
                      id="hs-default-checkbox" v-model="selectedData" value="individual">
                      Individual Observations
                  </label>
                </div>

                <div class="flex">
                  <label class="text-sm text-gray-500 ms-3 dark:text-neutral-400 flex gap-2">
                    <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
                      focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none 
                      dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 
                      dark:checked:border-blue-500 dark:focus:ring-offset-gray-800" 
                      id="hs-checked-checkbox" v-model="selectedData" value="activity">
                    Activity Observations
                  </label>
                </div>

                <div class="flex">
                  <label class="text-sm text-gray-500 ms-3 dark:text-neutral-400 flex gap-2">
                    <input type="checkbox" class="shrink-0 mt-0.5 border-gray-200 rounded text-blue-600 
                      focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none 
                      dark:bg-neutral-800 dark:border-neutral-700 dark:checked:bg-blue-500 
                      dark:checked:border-blue-500 dark:focus:ring-offset-gray-800" id="hs-checked-checkbox" 
                      v-model="selectedData" value="mention">
                    Student Mentions
                  </label>
                </div>
              </div>
            </div>
            <div class="flex justify-end gap-4">
              <button type="button" @click="hideFullPrompt = true" 
                v-if="promptResource.data?.status === 'success' && !hideFullPrompt"
                class="py-3 px-4 inline-flex items-center gap-x-2
                text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
                hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit">
                Hide Full Prompt
              </button>
              <button type="button" @click="fetchFullPrompt"
                class="py-3 px-4 inline-flex items-center gap-x-2
                text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
                hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit">
                Show Full Prompt
              </button>
              <button type="button" @click="generateNewReport"
                class="py-3 px-4 inline-flex items-center gap-x-2
                text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
                hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit">
                Generate New Report
              </button>
            </div>
          </div>
          <div class="mt-3">
            <p class="font-semibold">Existing Reports:</p>
            <div v-if="props.reportListResource.data?.status === 'success'"
              class="flex flex-col gap-2 min-h-96 lg:max-h-[50vh] p-4">
              <div v-for="report in props.reportListResource.data.reports"
                class="border rounded p-2 cursor-pointer" @click="() => selectReport(report.name)">
                <div class="flex justify-between">
                  <div>Start date: {{ report.start_date }}</div>
                  <div>End date: {{ report.end_date }}</div>
                  <div>Model: {{ report.model }}</div>
                </div>
                <h5 class="font-semibold text-gray-700">
                  Prompt: <span v-if="report.prompt_link?.name">{{ report.prompt_link.prompt_name }}</span>
                </h5>
                <p :class="cn(`text-ellipsis font-light text-gray-600`, 
                  selectedReport !== report.name && ' line-clamp-3')">
                  {{ report.prompt }}
                </p>
                <h5 class="font-semibold text-gray-700">
                  Report:
                </h5>
                <!-- <p :class="cn(`text-ellipsis font-light text-gray-600`, 
                  selectedReport !== report.name &&  ' line-clamp-4')">
                  {{ mdToHtml(report.report) }}
                </p> -->
                <div v-html="mdToHtml(report.report)" :class="cn(`text-ellipsis font-light text-gray-600`, 
                  selectedReport !== report.name &&  ' line-clamp-4')">
                </div>
              </div>
              <div v-if="!props.reportListResource.data.reports.length">
                No Existing reports found for the student!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import { cn } from '@/components/ui/utils.js'
import { HSOverlay } from 'preline/preline';
import { createResource } from 'frappe-ui'
import { marked } from 'marked'

const props = defineProps([
  'modalName', 'reportListResource', 'prompt',
  'startDate', 'endDate', 'generateReport', 
  'studentId', 'model', 'shouldLoadAll',
  'promptId', 'reportType', 'academicYear',
  'interval'
])

onMounted(() => {
  setTimeout(() => {
    window.HSStaticMethods.autoInit();
  }, 100)
});

const promptExpanded = ref(false)

// const isSubmitting = ref(false)
// const isSaved = ref(false)
const selectedReport = ref('')
const selectReport = (id) => {
  if (selectedReport.value === id)
    selectedReport.value = ''
  else
    selectedReport.value = id
}

const assessmentType = ref('raw')
const selectedData = ref(['individual', 'activity', 'mention'])

const generateNewReport = () => {
  props.generateReport(assessmentType.value, selectedData.value);
  HSOverlay.close(`#hs-${props.modalName}`);
}

const hideFullPrompt = ref(false)

const promptResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.common.observations.summary.api.get_individual_full_prompt',
})

const fetchFullPrompt = async () => {
  try {
    const generateparams = {
      student: props.studentId, 
      start_date: props.shouldLoadAll ? "" : props.startDate,
      end_date: props.shouldLoadAll ? "" : props.endDate,
      assessment_type: assessmentType.value,
      data_options: selectedData.value,

      prompt_id: props.promptId,
      custom_dates: props.shouldLoadAll ? false : true,
      report_type: props.reportType,
    }

    if (props.shouldLoadAll && props.reportType === 'yearly') {
      // generateparams.academic_year = props.interval;
      console.error("unable to fetch full prompt, change report type")
    } else if (props.shouldLoadAll && props.reportType === 'Term') {
      generateparams.academic_year = props.academicYear;
      generateparams.interval = props.interval;
    }

    console.log({generateparams})
    await promptResource.submit(generateparams)

    if (promptResource.data?.status === 'success') {
      console.log('Successfully fetched full prompt', promptResource)
      hideFullPrompt.value = false
    } else {
      console.log(promptResource.data.message)
    }
  } catch (error) {
    console.log(`Failed to make prompt request: ${error}`)
  }
}

const mdToHtml = (mdText) => {
  return marked(mdText)
}

const MODELMAP = {
  'gpt-3.5-turbo-0125': 'GPT-3.5 Turbo',
  'gpt-4': 'GPT-4',
  'gpt-4-32k': 'GPT-4 32K',
  'gpt-4-turbo': 'GPT-4 Turbo',
  'gpt-4o': 'GPT-4o',
  'claude-3-5-sonnet': 'Claude 3.5 Sonnet'
}
</script>
<template>
  <div class="flex flex-col gap-4">
    <div v-if="studentObj">{{ studentObj.student_name }}</div>
    <SummaryControls :pickedModel="pickedModel" :pickNewModel="pickNewModel" :prompt="prompt" 
      :usePrompt="changePrompt" :reloadObservations="reloadObservations" 
      :checkForExistingReports="checkForExistingReports" :studentId="props.student"
      @change:start="(newStart) => startDate = newStart" @change:end="(newEnd) => endDate = newEnd"
      @change:loadAll="(newAll) => shouldLoadAllObservations = newAll" @change:report="(newReport) => reportType = newReport"
      @change:interval="(newIntervalVal) => interval = newIntervalVal" :intermediateReports="intermediateReportResource.data?.reports || []"
      :reloadIntermediateReports="reloadIntermediateReports"
      :openAllModal="openGenerateAllModal" @change:defaultPrompt="defaultPromptFlag = !defaultPromptFlag" />
    <div class="flex items-center space-x-2 text-gray-700" v-if="isGenerating">
      <div class="w-5 h-5 border-4 border-gray-300 border-t-blue-500 rounded-full animate-spin"></div>
      <span class="text-lg font-medium">
        <span class="" v-if="isGenerating">
          Generating Report. Please wait...
        </span>
      </span>
    </div>
    <GenerateAllDisplay :generateResource="generateForAllResource" :isGeneratingAll="isGeneratingAll"
      :prompt="prompt" :defaultFlag="defaultPromptFlag" />
    <!-- <GenerateAllDisplay v-if="isGeneratingAll" /> -->
    <SummaryDisplay v-if="prompt.name || observationResource.data?.competency_scores" :prompt="prompt" 
      :summary="summaryText" :isGenerating="isGenerating" :log="logText"
      :competencyScores="observationResource.data.competency_scores" />
    <ObservationList v-if="observationResource.data?.status=='success'" 
      :intermediateReports="intermediateReportResource.data?.reports || []"
      :dailyObservList="observationResource.data.observations" 
      :activityObservList=observationResource.data.activity_observations
      :activityMentionList="observationResource.data.activity_mentions"
      :assessmentReport="observationResource.data.assessment_reports[0] || null" />
  </div>
  <ReportModal modalName="checkReportsModal" :reportListResource="reportListResource" :prompt="prompt"
    :startDate="startDate" :endDate="endDate" :shouldLoadAll="shouldLoadAllObservations" :generateReport="generateReport" 
    :studentId="props.student" :model="pickedModel" :promptId="prompt.name" :reportType="reportType"
    :academicYear="extractAcademicYear(interval)" :interval="extractIntervalIdentifier(interval)" />
  <GenerateAllModal modalName="generateAllModal" :reportListResource="reportListResource" :prompt="prompt"
    :startDate="startDate" :endDate="endDate" :shouldLoadAll="shouldLoadAllObservations" :generateReport="generateReport" 
    :studentId="props.student" :model="pickedModel" :generateReportsForAll="showConfirmAllDialog" />
  <GenerateAllDialog modalName="generateAllDialog" :generateAllFn="generateReportsForAll" />
</template>

<script setup>
import { computed, inject, ref, watch } from 'vue';
import { createResource } from 'frappe-ui';
import { useRoute } from 'vue-router';
import ObservationList from './ObservationList.vue';
import SummaryDisplay from './SummaryDisplay.vue';
import SummaryControls from './SummaryControls.vue';
import ReportModal from './ReportModal.vue';
import GenerateAllModal from './GenerateAllModal.vue';
import GenerateAllDialog from './GenerateAllDialog.vue';
import GenerateAllDisplay from './GenerateAllDisplay.vue'
import { getDateFromPreviousMonth, getTodayDateJSFormat } from '../../../../lib/utils';
import { HSOverlay } from 'preline/preline';
import LoadingText from 'frappe-ui/src/components/LoadingText.vue';

const props = defineProps(['cohortStudents', 'student'])

const route = useRoute()
const studentId = ref(route.query.student || '')

const userDetails = inject('userDetails')
const studentObj = computed(() => {
  if (!props.student) return {}
  if (!userDetails.value?.school_info) return {}
  const student = userDetails.value.school_info.students.find((obj) => obj.name === props.student)
  return student || {}
})

function getAcademicYears(endDate) {
    let years = [];
    let startYear = 2023; // Adjusted to include 2023-2024
    let currentYear = startYear;
    let endYear = endDate.getFullYear();
    let endMonth = endDate.getMonth() + 1;

    while (currentYear < endYear || (currentYear === endYear && endMonth >= 6)) {
        years.push(`${currentYear}-${currentYear + 1}`);
        currentYear++;
    }
    return years;
}

function getSchoolTerms(endDate) {
    let terms = [];
    let startYear = 2024;
    let currentYear = startYear;
    let currentMonth = 1;
    let endYear = endDate.getFullYear();
    let endMonth = endDate.getMonth() + 1;

    while (currentYear < endYear || (currentYear === endYear && currentMonth <= endMonth)) {
        let termLabel = currentMonth === 1 ? `January-June ${currentYear}` : `August-December ${currentYear}`;
        terms.push(termLabel);
        
        if (currentMonth === 1) {
            currentMonth = 8;
        } else {
            currentMonth = 1;
            currentYear++;
        }
    }
    return terms;
}
const academicYears = getAcademicYears(new Date());
const schoolTerms = getSchoolTerms(new Date());

const today = getTodayDateJSFormat()
const endDate = ref('2024-12-31')
const startDate = ref('2024-08-01')
const reportType = ref('Term')
const interval = ref(schoolTerms[schoolTerms.length - 2]);
// should load intervals
const shouldLoadAllObservations = ref(true)

const defaultPromptFlag = ref(false)

const observationResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.common.observations.summary.api.get_individual_observations',
})

const intermediateReportResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.intermediate_student_reports.api.get_reports',
})

function extractAcademicYear(intervalString) {
    const parts = intervalString.split(" ");
    if (parts.length !== 2) {
        throw new Error(`Invalid format: ${intervalString}`);
    }

    const interval = parts[0];
    const year = parseInt(parts[1], 10);

    if (interval === "August-December") {
        return `${year}-${year + 1}`; // Aug-Dec belongs to the starting academic year
    } else if (interval === "January-June") {
        return `${year - 1}-${year}`; // Jan-June belongs to the ending academic year
    }

    throw new Error(`Unknown interval: ${intervalString}`);
}

function extractIntervalIdentifier(intervalString) {
    return intervalString.split(" ")[0]; // Return only the first part before space
}

if (studentId) {
  observationResource.submit({ 
    student: studentId.value, 
    start_date: startDate.value,
    end_date: endDate.value,
    // load intervals
    load_all: false,
  })
  intermediateReportResource.submit({
    student: studentId.value,
    report_type: reportType.value,
      academic_year: extractAcademicYear(interval.value),
      interval: extractIntervalIdentifier(interval.value),
  })
  console.log({intermediateReportResource})
  console.log({observationResource})
}

const reloadIntermediateReports = (newReportType, newInterval) => {
  let filters = null;
  if (newReportType === 'Yearly') {
    filters = {
      student: studentId.value,
      report_type: newReportType,
      academic_year: newInterval,
    }
  } else if (newReportType === 'Term') {
    filters = {
      student: studentId.value,
      report_type: newReportType,
      academic_year: extractAcademicYear(newInterval),
      interval: extractIntervalIdentifier(newInterval),
    }
  }
  intermediateReportResource.submit(filters);
  console.log("Reloaded intermediare reports", intermediateReportResource)
  console.log("Intermediate filters", filters);
}

watch([reportType, interval], ([newReportType, newInterval]) => {
  console.log("intervals changed", [reportType, interval])
  reloadIntermediateReports(newReportType, newInterval);
})

const reloadObservations = () => {
  const params = { 
    student: route.query.student,
    start_date: startDate.value,
    end_date: endDate.value,
    // change to load intervals
    load_all: false,
    // load_all: shouldLoadAllObservations.value,
  }
  observationResource.submit(params)
  console.log({ params })
  console.log(observationResource)
}
watch(() => route.query.student, () => {
  reloadObservations()
})

const pickedModel = ref('claude-3-5-sonnet')
const prompt = ref({ name: '', prompt_name: '', prompt: '' })

const pickNewModel = (newModel) => {
  pickedModel.value = newModel
}

const changePrompt = (newPrompt) => {
  prompt.value = newPrompt
}

watch(() => observationResource.data, (newData) => {
  if (newData.default_prompt)
    prompt.value = newData.default_prompt
})

const summaryText = ref('')
const logText = ref('')
const isGenerating = ref(false)

const generateReportResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.common.observations.summary.api.get_individual_observ_report',
})

const generateForAllResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.common.observations.summary.api.generate_report_for_all',
})

const reportListResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.common.observations.summary.api.get_saved_reports',
})

const checkForExistingReports = async () => {
  try {
    await reportListResource.submit({ student: studentId.value })
    if (reportListResource.data.status === 'success') {
      console.log(reportListResource)
      HSOverlay.open('#hs-checkReportsModal')
    } else {
      console.error('Server call failed')
      console.log(reportListResource)
    }
  } catch (error) {
    console.error(`Failed to make report list request: ${error}`)
  }
}

const openGenerateAllModal = () => {
  HSOverlay.open('#hs-generateAllModal')
}

const assessmentTypeAll = ref('raw')
const selectedDataAll = ref(['individual', 'activity', 'mention'])

const showConfirmAllDialog = (assessment_type, data_options) => {
  assessmentTypeAll.value = assessment_type
  selectedDataAll.value = data_options
  HSOverlay.open('#hs-generateAllDialog')
}

const isGeneratingAll = ref(false)

const generateReportsForAll = async () => {
  HSOverlay.close('#hs-generateAllDialog')
  isGeneratingAll.value = true;
  try {
    await generateForAllResource.submit({
      assessment_type: assessmentTypeAll.value,
      data_options: selectedDataAll.value,
    })
  } catch (error) {
    console.error("Error making generate request: ")
    console.error(error)
  }

}

const generateReport = async (assessmentType, dataOptions) => {
  isGenerating.value = true
  try {
    const generateParams = {
      student: studentId.value,
      start_date: shouldLoadAllObservations.value ? "" : startDate.value,
      end_date: shouldLoadAllObservations.value ? "" : endDate.value,
      prompt: prompt.value.prompt,
      prompt_id: prompt.value.name,
      model: pickedModel.value,
      assessment_type: assessmentType,
      data_options: dataOptions,
      custom_dates: shouldLoadAllObservations.value ? false : true,
      report_type: reportType.value,
    }

    // add correct interval identifiers
    // should load means load interval - repurposed variable from before
    if (shouldLoadAllObservations.value && reportType.value === 'Yearly') {
      generateParams.academic_year = interval.value;
    } else if (shouldLoadAllObservations.value && reportType.value === 'Term') {
      generateParams.academic_year = extractAcademicYear(interval.value);
      generateParams.interval = extractIntervalIdentifier(interval.value);
    }

    console.log("Datat option for generate", {generateParams})
    await generateReportResource.submit(generateParams)
    if (generateReportResource.data.status === 'success') {
      summaryText.value = generateReportResource.data.report;
      logText.value = generateReportResource.data.log_text;
      console.log(`Summary generated in ${generateReportResource.data.api_time}s`)
      console.log('Tokens: ', generateReportResource.data.tokens)
    } else {
      console.log(`Failed to generate report: ${generateReportResource.data.error}`)
    }
  } catch (error) {
    console.error(`Failed to make generate request: ${error}`)
  }
  isGenerating.value = false
}
</script>